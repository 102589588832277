import * as React from "react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { cardRadius, colors, shadows } from "../theme/theme"
import styled from "@emotion/styled"

const StyledAlert = styled(Alert)`
  background-color: ${colors.red};
  color: ${colors.white};
  border-radius: ${cardRadius};
  box-shadow: ${shadows.containerShadow};
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <StyledAlert
      status="error"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="3rem" />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Oops
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Sorry, I don't have a creative 404 page. There's nothing here though.
      </AlertDescription>
    </StyledAlert>
  </Layout>
)

export default NotFoundPage
